<template>
  <div>
    <div class="container">
      <div class="row m-12 justify-content-center align-items-center">
        <div class="col-12 align-content-center justify-content-center">
          <h3>Лента сюжетов
            <span>
              <a href="javascript:void(0)" v-b-modal.HelpForm data-toggle="modal" data-target="#HelpForm"
                style="border: none;" class="text-primary"><help-circle-icon class="fea icon-sm"></help-circle-icon></a>
            </span>
            <b-modal id="HelpForm" title="Справка по сервису" centered button-size="sm" size="lg" ok-only>
              <div class="bg-white p-1 rounded box-shadow">
                <div class="text-muted mb-2" style="font-size: small;">
                  <p>
                    Сейчас Вы видите главную страницу ленты сюжетов, на ней показаны сюжеты и дополнительная информация к
                    ним.
                  </p>

                  <p>
                    Для получения сюжетов мы берем поток сообщений за определенный период (указан в шапке).
                    Эти сообщения обрабатываются и кластеризуются (т.е. разбиваются на некоторые группы).
                    К каждой группе мы автоматически подбираем название (это второй столбец в таблице), считаем количество
                    сообщений в нем с дублями и без (3 и 4 столбцы)
                  </p>

                  <p>
                    Табличку можно сортировать по числовым столбцам.
                  </p>

                  <p>
                    При нажатии на сюжет можно посмотреть наиболее интересные сообщений к нему. Отбор сообщений
                    производится по интегральной метрике связывающей сообщения с наибольшим охватом, количеством лайков и
                    тп.
                    Мы берем несколько таких показателей и строим на них некоторую интегральную метрику, и самые
                    интересные сообщения по этой метрике мы показываем. Рядом с каждым сообщением мы показываем ссылку на
                    источник.
                  </p>

                  <p>
                    Также для каждого сообщения показываются ключевые слова, посчитанные на всех сообщения сюжета - это
                    слова наиболее важные по разным метрикам для описания именно этого сюжета.
                  </p>

                  <p>
                    На данный момент имеется два потока данных. Один по сберу (в сообщениях обязательно должно встретится
                    ключевое слово из нашей экосистемы), второй - это общий поток.
                  </p>

                  <p>
                    Табличку можно скачать нажав на кнопку скачивания.
                  </p>

                  <p>
                    Создание кластеров мы проводим каждые полчаса или час в зависимости от потока.
                  </p>

                  <p>
                    Когда кластеризация готова, в последующие полчаса или час мы каждую минуту к уже созданным сюжетам
                    добавляем вновь пришедшие сообщения.
                    Время последней кластеризации и время последнего пришедшего сообщения показаны сверху.
                  </p>

                  <p>
                    По истечению получаса-часа мы считаем что информационная картина в мире могла поменяться, и могли
                    появиться новые сюжеты, и поэтому мы проводим сюжетирование заново - т.е. снова проводим
                    кластеризацию.
                    При такой процедуре после каждой кластеризации у нас может потеряться приемственность - ведь создаются
                    новые кластеры и мы не знаем как они связаны с предыдущими.
                    Поэтому у нас есть особый алгоритм связи кластеров, чтобы можно было смотреть историю развития
                    сюжетов.
                  </p>

                  <p>
                    Для того чтобы посмотреть историю сюжета нужно перейти по стрелочке вправо.
                  </p>
                </div>
              </div>
            </b-modal>
          </h3>
        </div>
        <div class="col-3 align-content-center justify-content-center">
          <h5>
            Сюжеты
          </h5>
          <div class="align-content-center justify-content-center">
            <div class="custom-control custom-radio custom-control-inline">
              <div class="form-group mb-0">
                <input type="radio" id="customRadio1" name="customRadio" checked @change="onChange(true)"
                  class="custom-control-input" />
                <label class="custom-control-label" for="customRadio1">Сбербанк</label>
              </div>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <div class="form-group mb-0">
                <input type="radio" id="customRadio2" name="customRadio" @change="onChange(false)"
                  class="custom-control-input" />
                <label class="custom-control-label" for="customRadio2">Макро</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-5 align-content-center justify-content-center text-info" style="font-size: small;">
          <ul>
            <li>
              Последний расчет сюжетов:
              <span v-if="loading">
                ...
              </span>
              <span v-else>
                <b>{{ start_time }}</b>
              </span>
            </li>
            <li>
              Дата добавления последнего сообщения:
              <span v-if="loading">
                ...
              </span>
              <span v-else>
                <b>{{ last_message_time }}</b>
              </span>
            </li>
            <li>
              ID кластеризации:
              <span v-if="loading">
                ...
              </span>
              <span v-else>
                <b>{{ clusterization_id }}</b>
              </span>
            </li>
          </ul>
        </div>
        <div class="col-3 align-content-center justify-content-center text-info" style="font-size: small;">
          <ul>
            <li>
              Частота сюжетирования:
              <span v-if="loading">
                ...
              </span>
              <span v-else>
                <span v-if="stream === 'SberBA'">
                  <b>30 минут</b>
                </span>
                <span v-else>
                  <b>1 час</b>
                </span>
              </span>
            </li>
            <li>
              Окно сюжетирования:
              <span v-if="loading">
                ...
              </span>
              <span v-else>
                <span v-if="stream === 'SberBA'">
                  <b>24 часа</b>
                </span>
                <span v-else>
                  <b>24 часа</b>
                </span>
              </span>
            </li>
            <li>
              Сообщения:
              <span v-b-tooltip.hover.top title="Все сообщения">
                <span v-if="loading">
                  ...
                </span>
                <span v-else>
                  <b>{{ num_of_all_mesages }}</b>
                </span>
              </span>
              (
              <span v-b-tooltip.hover.top title="Сообщения попавшие в содержательные кластеры">
                <span v-if="loading">
                  ...
                </span>
                <span v-else>
                  <b class="text-primary">{{ num_of_good_mesages }}</b>
                </span>
              </span>
              /
              <span v-b-tooltip.hover.top title="Сообщения НЕ попавшие в содержательные кластеры">
                <span v-if="loading">
                  ...
                </span>
                <span v-else>
                  <b class="text-danger">{{ num_of_bad_mesages }}</b>
                </span>
              </span>
              )
            </li>
          </ul>
        </div>
        <div class="col-1 align-content-center justify-content-center text-info" style="font-size: small;">
          <a @click="getExcel(clusterization_id)" class="btn btn-icon btn-outline-primary"><download-icon
              class="fea icon-sm"></download-icon></a>
        </div>
      </div>
      <div class="row m-12 justify-content-center align-items-center">
        <div class="col-12">
          <!-- sorted_clusters_list potentially calculates twise, fix it later-->
          <div v-if="~sorted_clusters_list & loading">
            Loading....
          </div>
          <div v-else class="table-responsive bg-white shadow rounded">
            <table class="table nocolor mb-0 table-center" style="table-layout:fixed;">
              <thead>
                <tr>
                  <th scope="col" style="width:7%; text-align:center; cursor: pointer;" @click="sort('rank')">
                    Ранк
                    <span v-if="currentSort === 'rank'">
                      <span v-if="currentSortDir === 'asc'">
                        <arrow-up-icon class="fea icon-sm"></arrow-up-icon>
                      </span>
                      <span v-else-if="currentSortDir === 'desc'">
                        <arrow-down-icon class="fea icon-sm"></arrow-down-icon>
                      </span>
                    </span>
                  </th>
                  <th scope="col" style="width:65%; text-align:center;">
                    Название сюжета
                  </th>
                  <th scope="col" style="width:10%; text-align:center; cursor: pointer;" @click="sort('raw_count')">
                    Число сообщений с дедуп.
                    <span v-if="currentSort === 'raw_count'">
                      <span v-if="currentSortDir === 'asc'">
                        <arrow-up-icon class="fea icon-sm"></arrow-up-icon>
                      </span>
                      <span v-else-if="currentSortDir === 'desc'">
                        <arrow-down-icon class="fea icon-sm"></arrow-down-icon>
                      </span>
                    </span>
                  </th>
                  <th scope="col" style="width:10%; text-align:center; cursor: pointer;" @click="sort('total_count')">
                    Число сообщений всего
                    <span v-if="currentSort === 'total_count'">
                      <span v-if="currentSortDir === 'asc'">
                        <arrow-up-icon class="fea icon-sm"></arrow-up-icon>
                      </span>
                      <span v-else-if="currentSortDir === 'desc'">
                        <arrow-down-icon class="fea icon-sm"></arrow-down-icon>
                      </span>
                    </span>
                  </th>
                  <th scope="col" style="winth:8%; text-align:center;">История</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(x, id) in sorted_clusters_list">
                  <tr @click="toggle(x.id)" :class="{ opened: opened.includes(x.id) }">
                    <td style="text-align:center;">{{ x.rank }}</td>
                    <td>
                      <p>{{ x.summary }}</p>
                      <div class="text-secondary" style="font-size: small;">
                        <template v-for="kw in x.keywords_yake">
                          {{ kw }}; &nbsp;
                        </template>
                      </div>
                    </td>
                    <td style="text-align:center;">{{ x.raw_count }}</td>
                    <td style="text-align:center;">{{ x.total_count }}</td>
                    <td style="text-align:center;">
                      <a @click="getHistory(x.clusterization_id, x.id)"><arrow-right-circle-icon class="fea icon-sm"
                          style="cursor: pointer;"></arrow-right-circle-icon></a>
                    </td>
                  </tr>
                  <tr v-if="opened.includes(x.id)"
                    style="background-color: #1f2d3d !important; color: #5a6d90 !important;">
                    <td colspan="5">
                      <div style="max-height:20em; width: 100%; overflow-y:scroll; overflow-x:auto;">
                        <table class="table mb-0 table-center text-secondary"
                          style="font-size:small; width:100%; table-layout:fixed;" rules="groups">
                          <colgroup>
                            <col span="1" style="width:90%;">
                            <col span="1" style="width:5%;">
                          </colgroup>
                          <tbody>
                            <template v-for="(m, m_id) in x.top3_messages_with_links">
                              <tr style="background-color: #1f2d3d !important; color: #5a6d90 !important;">
                                <td style="word-wrap: break-word">{{ m[0] }}</td>
                                <td>
                                  <a style="pointer-events: auto;" :href=m[1] target="_blank">ссылка</a>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import store from "@/store/store";

import { DownloadIcon, ArrowRightCircleIcon, ArrowUpCircleIcon, ArrowDownCircleIcon, ArrowUpIcon, ArrowDownIcon, HelpCircleIcon } from "vue-feather-icons";

export default {
  name: "topic-modelling-live",
  components: {
    DownloadIcon,
    ArrowRightCircleIcon,
    ArrowUpCircleIcon,
    ArrowDownCircleIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    HelpCircleIcon
  },
  data() {
    return {
      clusters_list: [{ 'clusterization_id': '' }],
      num_of_all_mesages: '',
      num_of_good_mesages: '',
      num_of_bad_mesages: '',
      start_time: '',
      last_message_time: '',
      currentSort: 'rank',
      currentSortDir: 'asc',
      clusterization_id: '',
      opened: [],
      loading: true,
      stream: "SberBA",
      // timer: '',
      in_sql: {
        id: 100000,
      },
    };
  },

  mounted() {
    this.getData();
    // this.timer = setInterval(this.fetchEventsList, 60000); // is ms; 1000ms = 1s; 60000ms = 60s = 1m
  },

  methods: {
    getHostname() {
      let basic_url = ''
      if (this.stream == "SberBA") {
        basic_url = basic_url + this.$hostname_ba
      } else {
        basic_url = basic_url + this.$hostname_ba_macro
      }
      return basic_url;
    },
    getData() {
      this.loading = true;
      this.axios({
        method: "get",
        url: this.getHostname() + "/plot-modelling/get-clusters-data",
      }).then((res) => {
        this.clusters_list = res.data.data;
        this.num_of_all_mesages = res.data.num_of_all_mesages;
        this.num_of_good_mesages = res.data.num_of_good_mesages;
        this.num_of_bad_mesages = this.num_of_all_mesages - this.num_of_good_mesages;
        this.start_time = res.data.start_time;
        this.last_message_time = res.data.last_message_time;
        this.clusterization_id = this.clusters_list[0].clusterization_id;
      });
    },
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    // cancelAutoUpdate () {
    //   clearInterval(this.timer);
    // }
    getExcel(x) {
      let requestData = {
        id: x
      };
      this.axios({
        method: 'post',
        url: this.getHostname() + "/plot-modelling/get-clusters-data-file",
        data: requestData,
        responseType: "blob",
      }).then(function (response) {
        const blob = new Blob(
          [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const aEle = document.createElement('a');     // Create a label
        const href = window.URL.createObjectURL(blob);       // Create downloaded link
        aEle.href = href;
        let datetime = new Date().toLocaleTimeString() + '_' + new Date().toLocaleDateString().replace(/\./g, '_');
        aEle.download = 'report_' + datetime + '.xlsx';  // File name after download
        document.body.appendChild(aEle);
        aEle.click();     // Click to download
        document.body.removeChild(aEle); // Download complete remove element
        window.URL.revokeObjectURL(href) // Release blob object
      })
    },
    getHistory(clusterisation_id, cluster_id) {
      let url = '' + 'history/' + clusterisation_id + '/' + cluster_id + '/' + this.stream;
      this.$router.push(url);
    },
    onChange(value) {
      this.stream = value ? "SberBA" : "MacroBA";
      this.getData();
    },
  },
  computed: {
    sorted_clusters_list: function () {
      this.loading = true;
      let a = this.clusters_list.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
      this.loading = false;
      return a;
    },
  },
  // beforeUnmount () {
  //   this.cancelAutoUpdate();
  // }
};
</script>

<style scoped>
.opened {
  background-color: #253649;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>